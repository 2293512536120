.rzv-market {
    padding: 5rem 2rem 0rem 2rem;

    &__wr {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (min-width: 64em) {
            flex-direction: row;
        }
    }
} 