.mp-logo {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: fit-content;
  position: relative;
  flex-basis: 33.33%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;

  &__link {
    text-decoration: none;
  }

  &__paragraph {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    text-decoration: none;
    position: absolute;
    min-width: 70px;

    span {
      display: inline-block;
      opacity: 0;
      animation-name: animation;
      animation-duration: 6s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;

      &:nth-child(1) {
        animation-delay: 0.1s; 
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
      }
      &:nth-child(5) {
        animation-delay: 0.5s;
      }
      &:nth-child(6) {
        animation-delay: 0.6s;
      }

    }

  }
}

@keyframes animation{
  0% { opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); filter: blur(10px) }
  25% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px) }
  75% { opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px) }
  100% {opacity: 1; transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg); filter: blur(0px) }
}