@import "../../assets/styles/colors.scss";

.rzv-legals {
  color: $clr-white;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 46em) {
    text-align: center;
  }

  @media (min-width: 80em) {
    text-align: left;
  }

  .rzv-love-icon {
    animation-name: beat;
    width: 1.2rem; height: 1.2rem;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .heart-icon {
    min-width: 30px;
    min-height: 40px;
    text-align: center;
    
    @media (min-width: 80em) {
      justify-content: flex-start;
    }  
  }

  .rzv-atm-paragraph {
    p {
      align-items: center;
      

     
    }
  }

  
}

@keyframes beat{
  0% {  color: #9e0000; width: .8rem; height: .8rem; opacity: .4 }
  25% { color: #ff0000; width: 1rem; height: 1rem; opacity: .6 }
  75% { color: #ff0000; width: 1.2rem; height: 1.2rem; opacity: .8 }
  100% {color: #ff0000; width: 1.4rem; height: 1.4rem; opacity: 1 }
}
