.rzv-info {
  width: 100%;
  text-align: left;
  &__wrapper {
    display: flex;
    justify-content: center;
  }
  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 46em) {
      padding-left: 0;
    }
  }
  &-item {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0 0 0;
    width: 100%;
    &__text {
      display: flex;
      flex-direction: row;
      margin: 0 1rem 0 0;
      text-align: left;
      font-size: 1rem;
      font-weight: 500;

    }
  }
}
