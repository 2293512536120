.rzv-product-add {
  min-width: 20rem;
  padding: 0 0 3rem 0;

  @media (min-width: 46em) {
    padding: 3rem 0;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    

    @media (min-width: 46em) {
      border-top: 1px solid #000;
    }
  }

  &-price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5%;
    @media (min-width: 64em) {
      justify-content: space-between;
      gap: 0;
    }
    input[type="number"] {
      max-width: 7rem;
    }
  }

  &__cta {
    padding-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4%;
    .rzv-a-cta {
      min-width: 10rem;
     
    }
    a {
        text-decoration: none;
        color: #000;
    }
  }
}
