.rzv-sign-up {
    padding: 1rem 3rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &__header {
        padding: 1rem 0 0 0;
    }

    &__cta {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10%;
        justify-content: center;
        padding: 1rem 0 0 0;
        text-decoration: none;

        .rzv-switch-cta {
            text-decoration: none;
            color: #000;
            font-size: .8rem;
        }
    }
}