.rzv-messagebar {
    &__top {
        height: 5px;
        width: 100%;
        background-color: #0057b7;
    }
    &__bottom {
        height: 5px;
        width: 100%;
        background-color: #ffd700;
    }
}