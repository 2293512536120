.rzv-product-edit {
    min-width: 20rem;
    padding: 0 0 3rem 0;
  
    @media (min-width: 46em) {
      padding: 3rem 0;
    }
  
    &-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      @media (min-width: 46em) {
        border-top: 1px solid #000;
      }
    }
  
    &__cta {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .rzv-a-cta {
        min-width: 10rem;
        &:disabled {
          background: #dddddd;
          &:hover{
            border: none;
            color: white;
          }
        }
        
      }
   
      a {
          text-decoration: none;
          margin-left: 1rem;
          color: #000;
      }
    }
  }
  