.mp-navbar {
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 0;
  margin-right: 2rem;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    height: 50px;
    //border-bottom: 2px solid #fff;
    padding: 0 1rem;
    @media screen and (min-width: 48em) {
      padding: 0 2rem;
    }
  }
  &__items {
    display: inline;
    width: 100%;
    height: 100%;
    flex-basis: 33.33%;
  }

  &__social {
    display: inline;
    width: 100%;
    height: 100%;
    flex-basis: 33.33%;
  }
}


// //
// /* The switch - the box around the slider */
// .switch {
//   position: relative;
//   display: inline-block;
//   width: 60px;
//   height: 34px;
// }

// /* Hide default HTML checkbox */
// .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// /* The slider */
// input[type="checkbox"] {
//   width: 0;
//   height: 0;
//   visibility: hidden;
// }
// label {
//   width: 50px;
//     height: 20px;
//     display: block;
//     background-color: #477a85;
//     position: relative;
//     top: 15px;
//     cursor: pointer;
//     transition: 0.5s;
//     box-shadow: 0 0 20px #477a8550;
// }
// label::after {
//   content: "";
//   width: 20px;
//   height: 15px;
//   background-color: #e8f5f7;
//   position: absolute;
//   top: 3px;
//   left: 5px;
//   transition: 0.5s;
// }

// input:checked + label:after {
//   left: calc(100% - 10px);
//   transform: translateX(-100%);
// }

// input:checked + label {
//   background-color: #243d42;
// }
