.mp-weather {
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    margin: 0 0rem 0 2rem;
    &-item {
        display: flex;
        flex-direction: row;
        width: auto;
        padding: 0 0.5rem;
        align-items: center;
        color: white;
        top: 0;
        height: 100%;
        position: relative;
        &__temp {
            padding: 0 0.8rem;
        }
    }
 
    &__container {
        display: flex;
        flex-direction: row;
        height: 100%;
        position: relative;
        right: 0;
    }
    svg {
        position: relative;
        padding: 0 0.8rem 0;
    }
}

.mp-a-paragraph {
    padding: 0;
}
