.rzv-product-list {
  //padding: 0 calc(100% / 8) 0 calc(100% / 8);
  width: 100%;
  background: transparent;
  @media (min-width: 64em) {
    min-width: 40rem;
    width: calc(100% / 2);
  }

  &__header {
    min-height: 4rem;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .rzv-a-cta {
      a {
        max-height: 2rem;
        text-decoration: none;
        color: #fff;
      }
    }

    .rzv-product-list__price {
      color: #fff;
      font-weight: 700;
      &--integer {
        font-size: 1.8rem;
      }
      &--cents {
        font-size: 1.2rem;
      }
    }
    
  }

  &__wr {
    max-height: 30rem;
    overflow-y: scroll;
    padding: 0rem 1rem;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(173, 173, 173);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(187, 187, 187);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(141, 141, 141);
    }
  }
  
}