#tsparticles {
    display: none;
    @media (min-width: 46em) {
        display: block;
    } 

    canvas {
        width: calc(100vw / 3) !important;
    }

}