.rzv-modal {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;

  justify-content: center;
  
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 100;
  }

  &__wr {
    background-color: #fff;
    margin-top: 5rem;
    width: calc(100vw * 3 / 3.5);
    min-height: 10rem;
    top: 0;
    z-index: 105;
    position: fixed;

    @media (min-width: 46em) {
        width: calc(100vw * 3 / 7);
    }
  }

}

.-opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
}
