.rzv-main {
  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    //background-color: #fff;
    padding-top: 50px;

    @media (min-width: 46em) {
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  // Black side on left
  &__left {
    color: #fff;
    height: 100vh;
    background-color: black;
    flex-direction: column;
    height: auto;
    z-index: 1;
    padding: 1.5em 1.5em;
    border-top: 1px solid #fff;
    
    @media (min-width: 46em) {
      height: 100vh;
      background-color: black;
      border-top: none;
      min-width: 33.33vw;
      padding:0;
      position: fixed;
      left: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      text-align: left;
      margin: 0;
      height: 85%;
      width: 100%;
      z-index: 5;
      justify-content: space-between;
      padding: 0;
      

      @media (min-width: 46em) {
        position: fixed;
        width: auto;
        padding: 2rem 3rem 0;
      }

      @media (min-width: 64em) {
        justify-content: space-between;
        min-width: 20%;
      }

      .rzv-main_item {
        display: flex;
        flex-direction: column;

        &__footer {
          @media (min-width: 80em) {
            align-items: center;
            justify-content: center;
            padding: 0 4rem 0 0;
          }
          
        }

        h2 {
          border-bottom: 1px solid #fff;
          padding-bottom: 6px;
          width: max-content;
          margin-top: 0;
        }

        a {
          color: grey;
          text-decoration: none;
          margin-top: 20px;
          width: 100%;

          .rzv-a-cta {
            color: #fff;
            width: 100%;
            font-size: 1rem;
  
            &:hover {
              color: #000;
            }
          }


        }

        
      }

    }
  }
  // White side on left
  &__right {
    display: flex;
    flex-direction: column;
    padding: 1.5em 1.5em;

    @media (min-width: 46em) {
      padding: 2rem 6rem 0 6rem;
      max-width: calc(100% / 2);
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @media (min-width: 46em) {
      justify-content: flex-start;
    }

    &_title {
      text-align: left;

      @media (min-width: 46em) {
        text-align: left;
      }

      .mpa-title {
        padding-top: 0;
      }
    }

    &_image {
      padding-right: 2.5rem;
      max-height: 2rem;

      @media (min-width: 46em) {
        padding-right: 0;
        padding-left: 5rem;
      }

      @media (min-width: 64em) {
        padding-right: 0;
        padding-left: 5rem;
      }
      
    }
  }

  &__body {
    width: 100%;
  }

  @media (min-width: 46em) {
    flex-direction: row;
  }
}
